import React, { useCallback } from 'react';
import { CardLoginMethodItem } from './card-login-method-item';
import { VerificationMethod } from '../../../../utils';
import { type CardLoginMethodProps, OTP_DIGIT_COUNT } from '..';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const CardLoginMethod: React.FunctionComponent<CardLoginMethodProps> = ({
  phone,
  onMethodChange,
  onPhoneReplace,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSelectMethod = useCallback(
    async (method: VerificationMethod) => {
      if (!executeRecaptcha) return;
      const token = await executeRecaptcha('request_otp_admin_dashboard');
      onMethodChange(method, token);
    },
    [executeRecaptcha, onMethodChange],
  );

  return (
    <>
      <div className="card p-6 w-75">
        <div className="flex flex-col m-6 pt-2 pb-2">
          <h4 className="pb-1 text-primary">Pilih Metode Di Bawah</h4>
          <p className="pb-2">
            Pilih salah satu metode dibawah untuk mendapatkan kode{' '}
            {OTP_DIGIT_COUNT} digit
          </p>
          <CardLoginMethodItem
            title="WhatsApp ke nomor"
            phone={`+62${phone}`}
            icon="wa"
            onClick={() => {
              handleSelectMethod(VerificationMethod.whatsapp);
            }}
          />
          <CardLoginMethodItem
            title="SMS ke nomor"
            phone={`+62${phone}`}
            icon="sms"
            onClick={() => {
              handleSelectMethod(VerificationMethod.sms);
            }}
          />
        </div>
        <div className="ml-6">
          <a href="#" onClick={onPhoneReplace}>
            UBAH NOMOR
          </a>
        </div>
      </div>
    </>
  );
};
