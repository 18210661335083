import { type StyleSheet } from '../../../../utils';

export const styles: StyleSheet = {
  otpInput: {
    width: 48,
    height: 48,
    textAlign: 'center',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
  },
};
