import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { type CardLoginOtpProps, styles } from '.';
import { LoadingContext } from '../../../modals/loading';

const DEFAULT_OTP_COUNT_DOWN = 120;
export const OTP_DIGIT_COUNT = 6;

export const CardLoginOtp: React.FunctionComponent<CardLoginOtpProps> = ({
  phone,
  onPhoneReplace,
  onSubmit,
}) => {
  const [countDown, setCountDown] = useState<number>(DEFAULT_OTP_COUNT_DOWN);
  const [resendEnabled, setResendEnabled] = useState<boolean>(false);
  const [otp, setOtp] = useState<Record<string, string>>({
    0: '',
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
  });

  const otp0 = useRef<HTMLInputElement>(null);
  const otp1 = useRef<HTMLInputElement>(null);
  const otp2 = useRef<HTMLInputElement>(null);
  const otp3 = useRef<HTMLInputElement>(null);
  const otp4 = useRef<HTMLInputElement>(null);
  const otp5 = useRef<HTMLInputElement>(null);

  const { startLoading, stopLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (!resendEnabled) {
      if (countDown <= 0) {
        setResendEnabled(true);
        setCountDown(DEFAULT_OTP_COUNT_DOWN);
      } else {
        const timeout = setTimeout(() => {
          setCountDown(countDown - 1);
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [countDown, resendEnabled]);

  const handleResendOTP = useCallback(() => {
    setResendEnabled(false);
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, boxNum: number) => {
      const { value } = event?.target;
      const newOtp = { ...otp, [boxNum]: event.target?.value };
      setOtp(newOtp);
      if (!value) {
        // Stay in the box when no value exist
        return false;
      }
      switch (boxNum) {
        case 0:
          otp1.current?.focus();
          otp1.current?.select();
          break;
        case 1:
          otp2.current?.focus();
          otp2.current?.select();
          break;
        case 2:
          otp3.current?.focus();
          otp3.current?.select();
          break;
        case 3:
          otp4.current?.focus();
          otp4.current?.select();
          break;
        case 4:
          otp5.current?.focus();
          otp5.current?.select();
          break;
        case 5:
          if (Object.keys(newOtp).length === OTP_DIGIT_COUNT) {
            startLoading();
            let otpString = '';
            Object.keys(newOtp).forEach((key) => {
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              otpString += newOtp[key];
            });
            onSubmit(otpString);
            stopLoading();
          }
          break;
      }
    },
    [onSubmit, otp, startLoading, stopLoading],
  );

  useEffect(() => {
    otp0.current?.focus();
  }, []);

  return (
    <div className="card p-6 w-75">
      <div className="flex flex-col m-6 pt-2 pb-2">
        <div className="pb-1 text-primary text-heading5">Verifikasi Nomor</div>
        <p className="pb-2">
          Silakan masukkan kode {OTP_DIGIT_COUNT} digit yang telah kami kirimkan
          ke nomor {`+62${phone}`}
        </p>
        <div className="flex justify-around pb-6 mt-6 mb-6">
          <input
            type="text"
            ref={otp0}
            value={otp[0]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event, 0)
            }
            style={styles.otpInput}
            maxLength={1}
            className="outline-none focus:border-primary"
          />
          <input
            type="text"
            ref={otp1}
            value={otp[1]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event, 1)
            }
            style={styles.otpInput}
            maxLength={1}
            className="outline-none focus:border-primary"
          />
          <input
            type="text"
            ref={otp2}
            value={otp[2]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event, 2)
            }
            style={styles.otpInput}
            maxLength={1}
            className="outline-none focus:border-primary"
          />
          <input
            type="text"
            ref={otp3}
            value={otp[3]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event, 3)
            }
            style={styles.otpInput}
            maxLength={1}
            className="outline-none focus:border-primary"
          />
          <input
            type="text"
            ref={otp4}
            value={otp[4]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event, 4)
            }
            style={styles.otpInput}
            maxLength={1}
            className="outline-none focus:border-primary"
          />
          <input
            type="text"
            ref={otp5}
            value={otp[5]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event, 5)
            }
            style={styles.otpInput}
            maxLength={1}
            className="outline-none focus:border-primary"
          />
        </div>
        <p className="mb-1 font-bold">Tidak menerima kode?</p>
        {resendEnabled ? (
          <p className="mb-1">
            <a href="#" onClick={handleResendOTP}>
              KIRIM ULANG OTP
            </a>
          </p>
        ) : (
          <p className="mb-1">
            Kirim ulang kode setelah{' '}
            <span className="text-danger">{countDown} detik</span>
          </p>
        )}
        <div
          className="mb-0 text-button text-primary cursor-pointer"
          onClick={onPhoneReplace}
          style={{ letterSpacing: '1.25px' }}
        >
          UBAH NOMOR
        </div>
      </div>
    </div>
  );
};
