import React, { useState, useMemo, type WheelEvent } from 'react';
import classNames from 'classnames';
import styles from './index.module.css';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { InputLabel } from '../input-label/input-label';

interface Props {
  containerClassName?: string; // mainly for applying margin or max-width
  className?: string;
  style?: React.CSSProperties; // usually for specific max-width, min-width
  value: any;
  placeholder?: string;
  onChange: (val: string) => void;
  onKeyEnter?: () => void;
  onFocus?: (val: string) => void;
  onBlur?: (val: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  id?: string;
  fontSize?: 'md' | 'lg';
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  prependClassName?: string; // mainly to adjust padding for prepend component
  appendClassName?: string; // mainly to adjust padding for prepend component
  type?: string;
  hint?: string; // *hint will be hidden if error is shown
  error?: boolean | string;
  label?: string;
  appendLabel?: React.ReactNode;
  maxLength?: number;
  showLengthIndicator?: boolean;
  readOnly?: boolean;
  step?: string;
  onWheel?: (e: WheelEvent<HTMLInputElement>) => void;
}

interface DisabledProps extends Omit<Props, 'onChange'> {
  disabled: true;
  onChange?: (val: string) => void;
}

interface ReadOnlyProps extends Omit<Props, 'onChange'> {
  readOnly: true;
  onChange?: (val: string) => void;
}

type LineInputProps = DisabledProps | ReadOnlyProps | Props;

export const LineInput = ({
  containerClassName,
  className,
  style,
  value,
  placeholder,
  onChange,
  onKeyEnter,
  onFocus,
  onBlur,
  disabled,
  autoFocus,
  id,
  fontSize,
  prepend,
  append,
  prependClassName,
  appendClassName,
  type,
  hint,
  error,
  label,
  appendLabel,
  maxLength,
  showLengthIndicator,
  readOnly,
  onWheel,
  ...rest
}: LineInputProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [passwordInputType, setPasswordInputType] = useState<
    'password' | 'text'
  >('password');

  const shouldShowLengthIndicator = useMemo(
    () => maxLength > 0 && showLengthIndicator,
    [maxLength, showLengthIndicator],
  );
  const shouldShowErrorMessage = useMemo(
    () => !!(typeof error === 'string' && error.length),
    [error],
  );
  const shouldShowHint = useMemo(() => hint?.length, [hint]);

  const inputPropsClass = {
    [styles.inputDisabled]: disabled,
    [styles.inputWithPrepend]: prepend,
    [styles.inputWithAppend]: append,
    [styles.inputError]: error,
  };

  const fontClass = {
    [styles.fontMedium]: fontSize === 'md',
    [styles.fontLarge]: fontSize === 'lg',
  };

  const PasswordToggle = (props) => {
    if (passwordInputType === 'password') {
      return <FiEyeOff {...props} />;
    }
    return <FiEye {...props} />;
  };

  const handleKeyEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.code === 'Enter') {
      onKeyEnter();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (maxLength && e.target.value.length > maxLength) {
      return;
    }
    onChange(e.target.value);
  };

  const togglePasswordInputType = () => {
    if (passwordInputType === 'password') {
      setPasswordInputType('text');
    } else {
      setPasswordInputType('password');
    }
  };

  const renderHintOrError = () => {
    if (shouldShowErrorMessage) {
      return (
        <span className="float-left text-caption text-error">{error}</span>
      );
    }

    if (shouldShowHint) {
      return (
        <span className="float-left text-caption text-secondary-gray">
          {hint}
        </span>
      );
    }

    return null;
  };

  const renderLengthIndicator = () => {
    if (shouldShowLengthIndicator) {
      return (
        <span className="float-right text-caption">
          {value?.length} / {maxLength}
        </span>
      );
    }

    return null;
  };

  return (
    <div
      className={classNames(styles.inputContainer, containerClassName, {
        'bg-fill-gray': disabled,
      })}
    >
      <div className="relative">
        {label && (
          <div className="flex gap-x-0.5 items-center">
            <InputLabel label={label} error={!!error} isFocused={isFocused}>
              {label}
            </InputLabel>
            {appendLabel && <div>{appendLabel}</div>}
          </div>
        )}
        <div
          className={classNames(styles.inputWrapper, {
            'border-error': error,
            'border-primary': isFocused,
          })}
        >
          {prepend && (
            <div
              className={classNames(styles.inputPrepend, prependClassName, {
                'text-secondary-gray': disabled,
              })}
            >
              {prepend}
            </div>
          )}
          {readOnly ? (
            <div className={classNames('pl-6 pt-1', className)}>{value}</div>
          ) : (
            <input
              type={passwordInputType === 'text' ? 'text' : type}
              className={classNames(
                styles.input,
                inputPropsClass,
                fontClass,
                className,
              )}
              style={style}
              value={value}
              placeholder={placeholder}
              onChange={handleChange}
              onKeyDown={handleKeyEnter}
              disabled={disabled}
              autoFocus={autoFocus}
              id={label ?? id}
              maxLength={maxLength}
              onFocus={(e) => {
                setIsFocused(true);
                onFocus(e.target.value);
              }}
              onBlur={(e) => {
                setIsFocused(false);
                onBlur(e.target.value);
              }}
              onWheel={(e) => {
                if (onWheel) onWheel(e);
              }}
              {...rest}
            />
          )}
          {append && (
            <div
              className={classNames(styles.inputAppend, appendClassName, {
                'text-secondary-gray': disabled,
              })}
            >
              {append}
            </div>
          )}
          {type === 'password' && (
            <PasswordToggle
              className="cursor-pointer absolute right-0 text-secondary-gray"
              size={18}
              onClick={togglePasswordInputType}
            />
          )}
        </div>
      </div>
      {(shouldShowLengthIndicator ||
        shouldShowHint ||
        shouldShowErrorMessage) && (
        <div className="w-full h-3.5 mt-1.5">
          {renderHintOrError()}
          {renderLengthIndicator()}
        </div>
      )}
    </div>
  );
};

LineInput.defaultProps = {
  onKeyEnter: () => {},
  onFocus: () => {},
  onBlur: () => {},
  type: 'text',
  showLengthIndicator: false,
};
