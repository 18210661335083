import React from 'react';
import { type CardLoginPhoneProps } from '.';
import { Button } from '../../../button';
import { BoxedInput } from '../../../forms/boxed-input';

export const CardLoginPhone: React.FunctionComponent<CardLoginPhoneProps> = ({
  phone,
  onPhoneChange,
  onSubmit,
  onEnter,
}) => {
  return (
    <div className="card p-6 w-full lg:w-3/4 xl:w-4/6">
      <div className="flex flex-col m-6 pt-2 pb-2">
        <div className="xl:pr-20">
          <div className="text-heading5 pb-1 text-primary">
            Masukkan Nomor Telepon
          </div>
          <div className="text-subtitle3 pb-2 my-2">
            Gunakan nomor telepon aktif Anda untuk menerima kode verifikasi
          </div>
        </div>
        <div className="flex items-center">
          <div className="p-4 text-body1">+62</div>
          <BoxedInput
            value={phone}
            onChange={onPhoneChange}
            placeholder="00"
            autoFocus
            onKeyEnter={onEnter}
            containerClassName="w-full"
            className="text-body1"
          />
        </div>
        <Button className="w-full mt-6" disabled={!phone} onClick={onSubmit}>
          LANJUTKAN
        </Button>
      </div>
    </div>
  );
};
