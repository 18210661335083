import React from 'react';
import { type CardLoginMethodItemProps } from './card-login-method-item.props';

export const CardLoginMethodItem: React.FunctionComponent<
  CardLoginMethodItemProps
> = ({ title, phone, icon, onClick }) => (
  <div
    className="card p-4 mt-2 shadow-sm border-0"
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <div className="card-body flex flex-row">
      <img
        src={`/img/icons/${icon}.png`}
        width="24"
        height="24"
        className="mr-6 self-center"
        alt="login"
      />
      <div>
        <p className="font-bold mb-0">{title}</p>
        <p className="mb-0">{phone}</p>
      </div>
    </div>
  </div>
);
