import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Head from 'next/head';
import { type NextPage } from 'next';
import { observer } from 'mobx-react';
import {
  CardLoginPhone,
  CardLoginMethod,
  CardLoginOtp,
  CardLoginStaff,
} from '../components/cards';
import { SnackbarContext } from '../components/alerts/snackbar';
import { LoadingContext } from '../components/modals/loading';
import { VerificationMethod } from '../utils/enums';
import {
  clearAccessTokenCookie,
  type CustomPageProps,
  MSG_GLOBAL_ERROR,
} from '../utils';
import { Api } from '../services/api';
import Router, { useRouter } from 'next/router';
import {
  FiArrowRight,
  FiGlobe,
  FiInstagram,
  FiUser,
  FiUsers,
} from 'react-icons/fi';
import { useStores } from '../stores/stores';

enum loginTypes {
  username,
  otp,
}
enum steps {
  phoneVerification,
  verificationMethod,
  otp,
}

const Login: NextPage<CustomPageProps> = observer(({ isReseller }) => {
  const router = useRouter();
  const { accountStore } = useStores();

  const [type, setType] = useState<loginTypes>(
    isReseller ? loginTypes.username : null,
  );

  // OTP login type
  const [phone, setPhone] = useState<string>('');
  const [step, setStep] = useState<steps>(steps.phoneVerification);
  const [sendtype, setSendtype] = useState<number | string>(null);

  // Username login type
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);

  const { showSnackbar } = useContext(SnackbarContext);
  const { startLoading, stopLoading } = useContext(LoadingContext);

  const api = useMemo(() => {
    const _api = new Api();
    _api.setup();
    return _api;
  }, []);

  useEffect(() => {
    if (api.checkTokenExist()) {
      Router.push('dashboard');
    }
  }, [api]);

  useEffect(() => {
    const isFromLogout = router.query.logout === 'true';
    if (isFromLogout) {
      showSnackbar({
        text: 'Anda berhasil mengakhiri sesi',
        type: 'success',
      });
      router.replace('/login', undefined, { shallow: true });
    }
  }, [router, router.query, showSnackbar]);

  const handlePhoneChange = useCallback((val: string) => {
    setPhone(val);
  }, []);

  const handlePhoneReplace = useCallback(() => {
    setStep(steps.phoneVerification);
  }, []);

  const handleMethodChange = useCallback(
    async (method: VerificationMethod, recaptchaToken: string) => {
      startLoading();
      const response = await api.tokenizeOtpRequest({
        phone: `0${phone}`,
        media_type: method === VerificationMethod.whatsapp ? 'wa' : 'sms',
        token: recaptchaToken,
        app: 'credimart',
      });

      if (response?.message || !response || response?.statusCode === 400) {
        showSnackbar({
          text: response?.message || MSG_GLOBAL_ERROR,
          type: 'danger',
        });
      } else {
        setStep(steps.otp);
        setSendtype(response?.sendtype);
      }
      stopLoading();
    },
    [api, phone, showSnackbar, startLoading, stopLoading],
  );

  const handlePhoneSubmit = useCallback(() => {
    setStep(steps.verificationMethod);
  }, []);

  const checkURLAuthorization = useCallback(async () => {
    const profile = await accountStore.getSellerProfile();
    const isResellerUrl =
      process.env.RESELLER_HOST_URL === window.location.host;
    const isDev = process.env.NODE_ENV === 'development';
    if (
      (profile?.reseller && !isResellerUrl && !isDev) || // Reseller user access non-reseller URL
      (!profile?.reseller && isResellerUrl && !isDev) // Supplier user access Reseller URL
    ) {
      showSnackbar({
        text: 'User tidak memiliki izin akses pada website. Silahkan gunakan aplikasi untuk login',
        type: 'danger',
      });
      await api.logout();
      window.localStorage.clear();
      clearAccessTokenCookie();
    } else {
      router.push('/dashboard');
    }
  }, [accountStore, api, router, showSnackbar]);

  const handleOtpSubmit = useCallback(
    async (otp: string) => {
      startLoading();
      const { success, message } = await api.otpVerifyV2({
        otp,
        sendtype,
        phone: `0${phone}`,
        app: 'credimart',
      });
      if (success) {
        await checkURLAuthorization();
      } else {
        showSnackbar({
          text: message || MSG_GLOBAL_ERROR,
          type: 'danger',
        });
      }
      stopLoading();
    },
    [
      api,
      checkURLAuthorization,
      phone,
      sendtype,
      showSnackbar,
      startLoading,
      stopLoading,
    ],
  );

  const handleEnter = useCallback(() => {
    handlePhoneSubmit();
  }, [handlePhoneSubmit]);

  // ===== Username login type ===== //

  const handleUsernameChange = useCallback(
    (val: string) => {
      if (isError) setIsError(false);
      setUsername(val);
    },
    [isError],
  );

  const handlePasswordChange = useCallback(
    (val: string) => {
      if (isError) setIsError(false);
      setPassword(val);
    },
    [isError],
  );

  const handleUsernameSubmit = useCallback(
    async (token) => {
      if (!username || !password) return;
      startLoading();
      const response = isReseller
        ? await api.loginStaff({ username, password, token })
        : await api.loginStaffV2({ username, password, token });
      if (!response?.success) {
        showSnackbar({
          text: response.message || MSG_GLOBAL_ERROR,
          type: 'danger',
        });
        stopLoading();
        return false;
      }
      const { success, message } = response;
      if (success) {
        await checkURLAuthorization();
      } else {
        if (message === 'restricted') {
          showSnackbar({
            text: 'User tidak memiliki izin akses pada website. Silahkan gunakan aplikasi untuk login',
            type: 'danger',
          });
        } else {
          setIsError(true);
          showSnackbar({
            text: message || MSG_GLOBAL_ERROR,
            type: 'danger',
          });
        }
      }
      stopLoading();
    },
    [
      api,
      checkURLAuthorization,
      isReseller,
      password,
      showSnackbar,
      startLoading,
      stopLoading,
      username,
    ],
  );

  // ===== End Username login type ===== //

  const RenderScene = useMemo(() => {
    switch (type) {
      case loginTypes.username:
        return (
          <CardLoginStaff
            username={username}
            password={password}
            onUsernameChange={handleUsernameChange}
            onPasswordChange={handlePasswordChange}
            isError={isError}
            disabled={!username || !password || isError}
            onSubmit={handleUsernameSubmit}
          />
        );
      case loginTypes.otp:
        switch (step) {
          case steps.phoneVerification:
            return (
              <CardLoginPhone
                phone={phone}
                onPhoneChange={handlePhoneChange}
                onSubmit={handlePhoneSubmit}
                onEnter={handleEnter}
              />
            );
          case steps.verificationMethod:
            return (
              <CardLoginMethod
                phone={phone}
                onPhoneReplace={handlePhoneReplace}
                onMethodChange={handleMethodChange}
              />
            );
          case steps.otp:
            return (
              <CardLoginOtp
                phone={phone}
                onPhoneReplace={handlePhoneReplace}
                onSubmit={handleOtpSubmit}
              />
            );
          default:
            return null;
        }
      default:
        return (
          <div className="flex flex-col gap-y-4">
            <div
              onClick={() => {
                setType(loginTypes.username);
              }}
              className="card"
            >
              <div className="p-4 shadow-sm card-login-type">
                <div className="icon mr-6">
                  <FiUsers size={24} />
                </div>
                <div className="mr-6 flex-1">
                  <div className="text-title-sm">Karyawan</div>
                  <div className="text-subtitle3 text-gray mt-1">
                    Masuk menggunakan User ID & Password
                  </div>
                </div>
                <FiArrowRight className="text-primary" size={24} />
              </div>
            </div>
            <div
              onClick={() => {
                setType(loginTypes.otp);
              }}
              className="card"
            >
              <div className="p-4 shadow-sm card-login-type">
                <div className="icon mr-6">
                  <FiUser size={24} />
                </div>
                <div className="mr-6 flex-1">
                  <div className="text-title-sm">Super Admin</div>
                  <div className="text-subtitle3 text-gray mt-1">
                    Masuk menggunakan OTP
                  </div>
                </div>
                <FiArrowRight className="text-primary" size={24} />
              </div>
            </div>
          </div>
        );
    }
  }, [
    type,
    username,
    password,
    handleUsernameChange,
    handlePasswordChange,
    isError,
    handleUsernameSubmit,
    step,
    phone,
    handlePhoneChange,
    handlePhoneSubmit,
    handleEnter,
    handlePhoneReplace,
    handleMethodChange,
    handleOtpSubmit,
  ]);

  return (
    <>
      <Head>
        <title>{`Login - Jooalan | ${
          isReseller ? 'Reseller' : 'Seller'
        } Web Dashboard`}</title>
      </Head>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      >
        <div className="front-face">
          <div className="grid grid-cols-1 lg:grid-cols-2 h-full m-0">
            <div className="h-screen">
              <div className="front-face-left py-12 flex flex-col justify-between bg-primary">
                <img
                  src="/img/logo-h-white.svg"
                  width="160"
                  className="mx-4 lg:mx-12"
                  alt="credimart"
                />
                <div className="text-white ml-4 lg:ml-12 mr-2 lg:mr-4 min-h-[200px]">
                  <div className="text-heading3 mb-1 xl:mb-0 xl:text-heading2 flex flex-wrap">
                    <span>Semua Bisa</span>
                    <span className="font-bold">&nbsp;#Jooalan</span>
                  </div>
                  <div className="flex flex-col text-heading6 tracking-[.15px] gap-y-2 mt-4">
                    <span>
                      Tingkatkan Penjualan dengan Jadikan Bisnismu Online.
                    </span>
                    <span>Lebih Mudah dan Cepat Pakai Jooalan!</span>
                  </div>
                </div>
                <div className="flex mx-4 lg:mx-12">
                  <a
                    href="http://jooalan.id/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FiGlobe className="text-white" size={20} />
                  </a>
                  <div className="mx-2" />
                  <a
                    href="https://www.instagram.com/jooalan.id/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FiInstagram className="text-white" size={20} />
                  </a>
                </div>
              </div>
            </div>
            <div className="h-screen">
              <div className="flex flex-col justify-center items-center h-full">
                {RenderScene}
              </div>
            </div>
          </div>
        </div>
      </GoogleReCaptchaProvider>
    </>
  );
});

Login.getInitialProps = async ({ req }) => {
  const isReseller = req.headers.host === process.env.RESELLER_HOST_URL;
  return { isReseller };
};

export default Login;
