import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './index.module.css';

interface Props {
  containerClassName?: string; // mainly for applying margin or max-width
  containerStyle?: React.CSSProperties; // mainly for applying margin or max-width
  className?: string;
  style?: React.CSSProperties; // usually for specific max-width, min-width
  value: any;
  placeholder?: string;
  onChange: (val: string) => void;
  onKeyEnter?: () => void;
  onBlur?: (val: string) => void;
  onAppendClick?: () => void;
  disabled?: boolean;
  autoFocus?: boolean;
  id?: string;
  fontSize?: 'md' | 'lg';
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  prependClassName?: string; // mainly to adjust padding for prepend component
  appendClassName?: string; // mainly to adjust padding for append component
  type?: string;
  error?: boolean | string;
  variant?: 'default' | 'filled'; // input component style theming
  label?: string;
  selectOnFocus?: boolean; // make all value selectable on focus.
  readOnly?: boolean;
}

interface DisabledProps extends Omit<Props, 'onChange'> {
  disabled: true;
  onChange?: (val: string) => void;
}

interface ReadOnlyProps extends Omit<Props, 'onChange'> {
  readOnly: true;
  onChange?: (val: string) => void;
}

type BoxedInputProps = DisabledProps | ReadOnlyProps | Props;

export const BoxedInput = ({
  containerClassName,
  containerStyle,
  className,
  style,
  value,
  placeholder,
  onChange,
  onKeyEnter,
  onBlur,
  disabled,
  autoFocus,
  id,
  fontSize,
  prepend,
  append,
  prependClassName,
  appendClassName,
  type,
  error,
  variant,
  label,
  selectOnFocus,
  readOnly,
  ...rest
}: BoxedInputProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const shouldShowErrorMessage = useMemo(
    () => !!(typeof error === 'string' && error.length),
    [error],
  );

  const inputClass = {
    [styles.inputDisabled]: disabled,
    [styles.inputWithPrepend]: prepend,
    [styles.inputWithAppend]: append,
    [styles.inputError]: error,
    [styles.inputFilled]: variant === 'filled',
  };

  const fontClass = {
    [styles.fontMedium]: fontSize === 'md',
    [styles.fontLarge]: fontSize === 'lg',
  };

  const handleKeyEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.code === 'Enter') {
      onKeyEnter();
    }
  };

  return (
    <div
      className={classNames(
        styles.inputContainer,
        fontClass,
        containerClassName,
      )}
      style={containerStyle}
    >
      <div className="relative">
        {label && (
          <label
            htmlFor={label}
            className={classNames(styles.inputLabel, {
              'text-error': error,
              'text-primary': isFocused,
            })}
          >
            {label}
          </label>
        )}
        {prepend && (
          <div
            className={classNames(styles.inputPrepend, prependClassName, {
              'ml-0 pt-1': readOnly,
            })}
          >
            {prepend}
          </div>
        )}
        {readOnly ? (
          <div className="pl-6 pt-1">{value}</div>
        ) : (
          <input
            type={type}
            className={classNames(styles.input, inputClass, className)}
            style={style}
            value={value}
            placeholder={placeholder}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onKeyDown={handleKeyEnter}
            onFocus={() => {
              selectOnFocus &&
                inputRef.current.setSelectionRange(0, value.length);
              setIsFocused(true);
            }}
            onBlur={(e) => {
              selectOnFocus && inputRef.current.blur();
              setIsFocused(false);
              onBlur(e.target.value);
            }}
            disabled={disabled}
            autoFocus={autoFocus}
            id={label ?? id}
            ref={inputRef}
            {...rest}
          />
        )}
        {append && (
          <div className={classNames(styles.inputAppend, appendClassName)}>
            {append}
          </div>
        )}
      </div>
      {shouldShowErrorMessage && (
        <div className="w-full h-3.5 mt-1.5">
          <span className="float-left text-caption text-error">{error}</span>
        </div>
      )}
    </div>
  );
};

BoxedInput.defaultProps = {
  onChange: () => {},
  onKeyEnter: () => {},
  onBlur: () => {},
  fontSize: 'md',
  type: 'text',
  variant: 'default',
};
