import React, {
  type DetailedHTMLProps,
  type HTMLAttributes,
  type FunctionComponent,
} from 'react';
import classNames from 'classnames';
import styles from './input-label.module.css';
import { type InputLabelProps } from './input-label.props';

export const InputLabel: FunctionComponent<
  InputLabelProps &
    DetailedHTMLProps<HTMLAttributes<HTMLLabelElement>, HTMLLabelElement>
> = ({ label, error, isFocused, className }) => {
  return (
    <label
      htmlFor={label}
      className={classNames(
        styles.inputLabel,
        {
          'text-error': error,
          'text-primary': isFocused,
        },
        className,
      )}
    >
      {label}
    </label>
  );
};
