import React, { useState, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FiLock, FiUser } from 'react-icons/fi';
import { type CardLoginStaffProps } from '.';
import { Button } from '../../button';
import { Tooltip } from '../../tooltip';
import { LineInput } from '../../forms/line-input';
import module from '../../../package.json';

export const CardLoginStaff: React.FunctionComponent<CardLoginStaffProps> = ({
  username,
  password,
  onUsernameChange,
  onPasswordChange,
  isError,
  onSubmit,
  disabled,
}) => {
  const [version, setVersion] = useState<string>('development');

  useEffect(() => {
    setVersion(module?.version);
  }, []);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = async () => {
    const token = await executeRecaptcha('request_otp_admin_dashboard');
    onSubmit(token);
  };

  return (
    <>
      <div className="card max-w-[384px] mb-8">
        <div className="card-body p-12">
          <div className="flex flex-col items-center text-center gap-y-4 mb-12">
            <div className="lg:mx-6 text-heading5">Selamat Datang!</div>
            <div className="text-subtitle3 text-secondary-gray">
              Silakan masukkan Nama Karyawan dan Password Anda.
            </div>
          </div>
          <div className="mb-4">
            <LineInput
              value={username}
              label="Nama Karyawan"
              placeholder="Tulis nama karyawan"
              onChange={onUsernameChange}
              autoFocus
              onKeyEnter={handleSubmit}
              className="text-subtitle3"
              error={isError}
              prepend={<FiUser size={16} className="text-secondary-gray" />}
              appendClassName="pointer-events-auto"
              appendLabel={
                <Tooltip
                  text={
                    'Pastikan login menggunakan “-” sebagai pemisah domain & nama karyawan'
                  }
                  position="top-end"
                  htmlClassName="text-left"
                  className="text-primary p-0.5"
                  size={16}
                />
              }
            />
          </div>

          <div className="mb-4">
            <LineInput
              type="password"
              value={password}
              label="Password"
              placeholder="Tulis password"
              onChange={onPasswordChange}
              onKeyEnter={handleSubmit}
              className="text-subtitle3"
              error={isError}
              prepend={<FiLock className="text-secondary-gray" size={16} />}
            />
          </div>

          <Button disabled={disabled} onClick={handleSubmit} fullWidth>
            Masuk
          </Button>
        </div>
      </div>
      <div className="text-secondary-gray">App Version {version}</div>
    </>
  );
};
