import React from 'react';
import {
  Tooltip as ReactTippy,
  type Position,
  type Trigger,
} from 'react-tippy';
import classNames from 'classnames';
import { FiInfo } from 'react-icons/fi';
import 'react-tippy/dist/tippy.css';

interface Props {
  text: string;
  size?: number;
  position?: Position;
  style?: React.CSSProperties;
  className?: string;
  trigger?: Trigger;
  htmlClassName?: string;
}

const ExtendedReactTippy = (props) => {
  return <ReactTippy {...props} />;
};

export const Tooltip = ({
  text,
  size = 20,
  position = 'top',
  style,
  className,
  trigger = 'mouseenter',
  htmlClassName,
}: Props) => {
  return (
    <ExtendedReactTippy
      html={
        <div className={htmlClassName} style={{ maxWidth: 280 }}>
          {text}
        </div>
      }
      position={position}
      trigger={trigger}
      arrow
    >
      <FiInfo
        size={size}
        style={style}
        className={classNames('inline-block cursor-pointer', className)}
      />
    </ExtendedReactTippy>
  );
};
